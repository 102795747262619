@import 'styles/vars.css';

/* Timeline */

.timelineRoot {
	height: 100%;
	width: 100%;
	max-width: var(--max-width);
	min-width: var(--min-width);
	position: relative;
}

.timelineContent {
	overflow: auto;
	margin: 0 auto;
	height: 100%;
	width: 100%;
	max-width: var(--max-width);
	min-width: var(--min-width);
}

.timelineContentGrid {
	flex-direction: row;
	display: flex;
	flex-wrap: wrap;
	align-content: flex-start;
	justify-content: flex-start;
}

.timelineWithAddPost {
	padding-bottom: 80px;
}

/* AddPostButton */

.addPostDraftMenuDivider {
	font-weight: bold;
	border-top: 1px solid black;
	color: black;
}

.plusButton {
	height: 56px !important;
	width: 56px !important;
	font-size: 24px !important;
	position: absolute !important;
	bottom: 12px !important;
	right: 12px !important;
	z-index: 20 !important;
}

.plusButtonSmall {
	right: 24px !important;
	bottom: 24px !important;
}

/* ChangeRequestButton */

.changeRequestModalRoot {
	justify-content: center;
	position: relative;
	background-color: white;
	width: 100%;
	max-width: var(--max-width);
	min-width: var(--min-width);
	max-height: 100%;
	overflow: auto;
	padding: 4px 12px;
}

.changeRequestModalTitle {
	width: 100%;
	max-width: var(--max-width);
	min-width: var(--min-width);
}

.changeRequesPostContainer {
	padding: 10px;
}

.changeRequestDisabled {
	color: var(--color-disabled);
}

/* ChangeRequestForm */

.changeRequestFormContainer {
	background-color: white;
	border-radius: 5px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;
	max-width: var(--max-width);
	min-width: var(--min-width);
	height: auto;
	padding: 24px 12px;
}

.changeRequestFormField {
	position: relative;
	width: 100%;
	margin: 8px 0;
}

.changeRequestFormButtonContainer {
	display: flex;
	justify-content: flex-end;
	width: 100%;
	padding-bottom: 12px;
}

/* ChangeRequestMessgage */

.changeRequestMessageRoot {
	margin: 30px 4px 12px;
	border: 1px solid var(--color-error);
	border-radius: 5px;
	padding: 24px 12px 12px;
	max-width: var(--max-width);
	min-width: var(--min-width);
	position: relative;
}

.changeRequestMessageTitle {
	position: absolute;
	top: -10px;
	background-color: var(--color-error);
	color: var(--color-contrast-text);
	padding-left: 12px;
	padding-right: 12px;
	border-radius: 5px;
}

.changeRequestMessageSeeMore {
	color: var(--color-main);
	cursor: pointer;
}

/* FullScreenImage */

.fullScreenRoot {
	overflow: auto;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.fullScreenContent {
	position: relative;
	width: 100%;
	max-width: var(--max-width);
	max-height: 18vh;
	margin-top: 16px;
	border-radius: 10px;
	background-color: white;
	z-index: 10;
	color: #151b26;
	padding: 16px 40px 16px 24px;
	overflow: auto;
}

.fullScreenTitleSection {
	font-size: 16px;
	font-weight: 700;
}

.fullScreenTitle {
	max-width: calc(100% - 70px);
}

.fullScreenClose {
	display: flex;
	position: absolute;
	top: 4px;
	right: 4px;
}

.fullScreenInfo {
	overflow-y: auto;
	max-height: calc(18vh - 48px);
}

/* ImageSelection */

.imageSelectionRoot {
	display: flex;
	width: 100%;
	justify-content: center;
	align-items: center;
	color: var(--font-main);
}

.imageSelectionRoot > div {
	margin: 0 4px;
}

.imageSelectionArrows {
	margin-top: 4px;
	cursor: pointer;
}

/* Post */

.postRoot {
	width: 100%;
	padding: 0 4px;
	max-width: var(--max-width);
	min-width: var(--min-width);
	border-bottom: solid 1px var(--color-divider);
}

.postContainer {
	width: 100%;
	display: grid;
	grid-template-areas: 'post-content';
	grid-template-columns: 1fr;
	position: relative;
	padding: 8px 0;
}

.postContainerWithImage {
	grid-template-areas: 'post-content post-image';
	grid-template-columns: 1fr 1fr;
}

.postContainerWithImageStacked {
	grid-template-areas: 'post-image' 'post-content';
	padding: 8px;
}

.postContent {
	grid-area: post-content;
}

.postImage {
	grid-area: post-image;
	width: 100%;
}

/* PostBody */

.postBody {
	font-size: 16px;
	margin: 8px 0 24px;
}

.postBody > span {
	color: var(--color-main);
	cursor: pointer;
}

/* PostContent */

.postContentRoot {
	margin-right: 8px;
}

.postContentTitle {
	font-weight: bold;
	font-size: 22px;
}

/* PostCreatedBy */

.postCreatedByRoot {
	color: var(--color-divider-dark);
	font-size: 16px;
	margin: 8px 0 24px;
}

/* PostImage */

.postImageRoot {
	width: 100%;
	position: relative;
}

.postImageRoot > img {
	width: 100%;
	position: relative;
	object-fit: cover;
}

.postImageRoot:hover > button {
	color: black;
	background-color: var(--color-gray);
}

.postImagePreviewIcon {
	position: absolute !important;
	right: 8px !important;
	top: 8px !important;
	box-shadow: 0 !important;
}

/* PostMenu */

.postMenuRoot {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	z-index: 100;
	margin-top: 8px;
}

/* PostTile */

.postTileRoot {
	width: 33%;
	aspect-ratio: 1;
	background-color: var(--color-background-active);
	overflow: hidden;
	cursor: pointer;
	border: 1px solid white;
}

/* PostTileNoImage */

.postTileNoImageModalRoot {
	background-color: white;
	width: 80%;
	max-width: var(--max-width);
	min-width: var(--min-width);
	padding: 8px;
}

.postTileNoImageRoot {
	background-color: var(--color-background-active);
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
}

.postTileNoImageAvatar {
	width: 40% !important;
	height: 40% !important;
	margin: 10px 20px !important;
	background-color: white !important;
	box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25), 0px 1px 4px rgba(0, 0, 0, 0.12) !important;
}

.postTileNoImageIcon {
	color: var(--color-main) !important;
	width: 40% !important;
	height: 40% !important;
}

/* ServiceCategoryChip */

.postCategoryChipRoot {
	font-size: 16px;
	margin: 8px 0 24px;
}

.postCategoryChipRoot > div > span {
	display: flex;
	align-items: center;
}

.postCategoryChipIcon {
	width: 16px !important;
	height: 16px !important;
	color: var(--color-main) !important;
	margin-right: 8px !important;
}

/* VaultReason */

.modalContainer {
	background-color: white;
	height: 200px;
	width: 450px;
	border-radius: 15px;
	padding-left: 1%;
	padding-top: 1%;
	display: flex;
	flex-direction: column;
}

.textContainer {
	display: flex;
	flex: 1;
	padding-left: 3%;
}

.reasonContainer {
	display: flex;
	flex: 2;
	padding-left: 3%;
	padding-right: 5%;
}

.buttonContainer {
	display: flex;
	align-items: flex-end;
	justify-content: flex-end;
	padding-bottom: 3%;
	padding-right: 5%;
	flex: 1;
}
