@import 'styles/vars.css';

.pageLayoutRoot {
	width: 100%;
	height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.pageLayoutChildren {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	overflow: auto;
	align-items: center;
}

.centerPageLayoutAlign {
	align-items: center;
}

.leftPageLayoutAlign {
	align-items: left;
	padding-left: 40px;
	padding-right: 40px;
}

.mobilePageLayoutAlign {
	padding-left: 10px;
	padding-right: 10px;
}

.pageLayoutHeader {
	display: flex;
	flex-direction: column;
	background-color: var(--color-background-active);
	z-index: 100;
	flex-shrink: 0;
}

.pageLayoutFooter {
	height: 112px;
	z-index: 100;
	flex-shrink: 0;
}

.pageLayoutFooterAndButtons {
	height: 172px;
}

/* Default Footer */

.defaultFooterRoot {
	width: 100%;
	height: 112px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-top: 1px solid var(--color-divider);
	box-shadow: 0px -3px 10px var(--color-divider);
}

.defaultFooterContent {
	height: 100%;
	width: 100%;
	max-width: var(--max-width);
	min-width: var(--min-width);
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-end;
	padding-right: 50px;
}

/* DefaultHeader */

.defaultHeaderRoot {
	width: 100%;
	height: 120px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 34px;
}

.defaultHeaderContent {
	height: 100%;
	width: 100%;
	max-width: var(--max-width);
	min-width: var(--min-width);
	display: flex;
	align-items: flex-end;
	justify-content: flex-start;
	padding-bottom: 10px;
	padding-left: 10px;
}

.mediumPadding {
	padding-left: 50px;
}

/* LayeredButtonContainer */

.layeredButtonContainer {
	position: sticky;
	bottom: 0;
	background-color: white;
	width: 100%;
	height: 60px;
	z-index: 100;
	border-top: 1px solid var(--color-divider);
	box-shadow: 0px -3px 10px var(--color-divider);
}

.layeredButtons {
	position: absolute;
	top: 14px;
	right: 80px;
}

/* Banner */

.bannerContainer {
	position: relative;
	width: 100%;
	height: 56px;
	display: flex;
	justify-content: flexStart;
	align-items: center;
}

.light {
	background-color: var(--color-gray);
	color: black;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.06), 0px 2px 2px rgba(0, 0, 0, 0.04),
		0px 1px 2px rgba(0, 0, 0, 0.04);
}

.dark {
	background-color: var(--color-main);
	color: white;
}

.bannerName {
	font-weight: 400;
	font-size: 20px;
}

@media only screen and (max-width: 660px) {
	.bannerName {
		font-size: 16px;
	}
	.bannerAvatar {
		margin: 0 16px 0 8px;
	}
}

.idString {
	margin-left: 16px;
}

.bannerButtonContainer {
	position: absolute;
	right: 16px;
	display: flex;
	justify-content: flex-end;
	align-items: center;
	height: 100%;
}

.bannerAvatar {
	background-color: var(--color-gray);
	color: var(--color-main);
}

.bannerChildren {
	width: 100%;
	height: calc(100% - 104px);
	display: flex;
	flex-direction: column;
	align-items: center;
	overflow: auto;
}

.footerbuttonscontainer {
	position: sticky;
	bottom: 0;
	background-color: var(--color-main);
	width: 100%;
	height: 48px;
	z-index: 100;
}

.footerbuttons {
	position: absolute;
	top: 8px;
	right: 16px;
}

/* Feed */

.feedContainer {
	position: relative;
	height: calc(100% - 60px);
	width: 100%;
	max-width: 100vw;
	z-index: 0;
}

/* InvalidToken */

.inviteContainer {
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	align-items: center;
}

.errorContent {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

/* Loader */

.loader {
	color: var(--color-main);
}

.loaderWindow {
	position: fixed;
	z-index: 9000;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	background-color: rgba(255, 255, 255, 0.503);
	display: flex;
	justify-content: center;
	align-items: center;
}

.loaderContainer {
	position: fixed;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 200px;
	width: 200px;
	font-size: 20px;
	color: var(--color-main);
}

/* NoPost */

.noPostContainer {
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
	font-size: 1.5em;
}

.noPostRoot {
	height: 100px;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.noPostTitle {
	text-align: center;
	margin: 16px 8px;
	font-size: 16px;
}

.noPostButtonContainer {
	display: flex;
	justify-content: center;
	align-items: center;
}

@media only screen and (max-width: 660px) {
	.noPostButtonContainer {
		flex-direction: column-reverse;
		& > button {
			margin: 4px 0;
		}
	}
}

.noPostHeightDefault {
	height: 100%;
}
.noPostHeightServiceSelector {
	height: calc(100% - 440px);
}
.noPostHeightNoSelector {
	height: calc(100% - 80px);
}

/* ReadOnlyContainer */

.readOnlyContainer {
	position: relative;
	height: 100%;
	display: flex;
	flex-direction: row;
}

.readOnlyContent {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	height: 100%;
}

.imageContainer {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.fullVaultEmpty {
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding-right: 5%;
	padding-left: 5%;
	padding-top: 10%;
}

.vaultHeader {
	font-size: 32px;
	font-weight: bold;
	display: flex;
	width: 100%;
	align-items: center;
}

.emptyLogo {
	width: 20%;
	height: 20%;
}
